.procedures{
    display: flex;
    width: 100%;
    background-color: white;

    
    .proceduresContainer{
        flex: 6;
    }

    .prcudures{
        padding: 20px;
    }

    .tpcdures{
        padding: 5px 20px;
    }
}