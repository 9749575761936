.investigations{
    display: flex;
    width: 100%;
    background-color: white;

    
    .investigationsContainer{
        flex: 6;
    }

    .invges{
        padding: 20px;
    }

    .tinvges{
        padding: 5px 20px;
    }
}