.message{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    .messageInfo{
        display: flex;
        flex-direction: column;
        color: gray;
        font-weight: 300;
        img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .messageContent{
        max-width: 80%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        p{
            background-color: white;
            padding: 10px;
            border-radius: 0px 10px 10px 10px;
            max-width: max-content;
        }

        img{
            width: 50%;
        }
    }

    &.owner{
        flex-direction: row-reverse;

        .messageContent{
            align-items: flex-end;
            p{
                background-color: #ca4200;
                color: white;
                border-radius: 10px 0px 10px 10px;
            }
        }

    }
}