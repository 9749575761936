.login{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../bgImg/babyStickers.jpeg");
    background-repeat: no-repeat;
    background-size: cover;

    .formWrapper{

        background-color: #ca4200;
        padding: 20px 60px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        
            form{
                display: flex;
                flex-direction: column;
                align-items: center;
        
                input{
                    padding: 8px;
                    width: 200px;
                    height: 30px;
                    margin: 10px;
                    border: none;
                    border-radius: 12px;
                    border-bottom: 1px solid  #ca4200;
                }
                button{
                   width: 210px;
                   height: 30px; 
                   border: none;
                   border-radius: 5px;
                   background-color: #7b96ec;
                   color: white;
                   font-weight: bold;
                   cursor: pointer;
                }

                p{
                    Link{
                        color: #f2f2f2;
                    }
                }
        
                span{
                    font-size: 12px;
                    color: #f2f2f2;
                    margin-top: 10px;
                }
            }
    }
}