.navchatbar{
    display: flex;
    align-items: center;
    background-color: #ca4200;
    height: 50px;
    padding: 10px;
    justify-content: space-between;
    color: #ddddf7;
    border-radius: 10px;

    .logo{
       font-weight: bold; 
    }

    .user{
        display: flex;
        gap: 10px;
    }

    img{
        background-color: #ddddf7;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        object-fit: cover;
    }
}