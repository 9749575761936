.cryopreservations{
    display: flex;
    width: 100%;

    
    .cryopreservationsContainer{
        flex: 6;
    }

    .cryops{
        padding: 20px;
    }
    
    .embr{
        padding: 5px 20px;
    }
    .emb{
        padding: 5px 20px;
    }

    .spms{
        padding: 5px 20px;
    }
}