.husband{
    display: flex;
    width: 100%;
    background-color: white;

    
    .husbandContainer{
        flex: 6;
    }

}