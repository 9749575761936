.bell{
    display: flex;
    width: 100%;
    background-color: white;
    
    .bellContainer{
        flex: 6;
    }

    .bcl{
        padding: 20px;
    }

    .trackclients{
        padding: 5px 20px;
    }
}