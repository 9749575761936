.medicalhsy{
    display: flex;
    width: 100%;
    background-color: white;
    
    .medicalhsyContainer{
        flex: 6;

        .mh{
            padding: 20px;
        }

        .gnhsy{
            padding: 5px 20px;
        }

        .obst{
            padding: 5px 20px;
        }

        .mdc{
            padding: 5px 20px;
        }

        .hums{
            padding: 5px 20px;
        }
    }
}