.nursing{
    display: flex;
    width: 100%;
    background-color: white;
    
    .nursingContainer{
        flex: 6;
    }

    .nnotes{
        padding: 20px;
    }

    .nnt{
        padding: 5px 20px;
    }
}