.schat{
    flex: 2;
    

    .chatInfo{
        height: 50px;
        background-color: #ca4300a8;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        color: lightgray;
        border-radius: 10px;
        .chatIcons{
    
            .icon{
                display: flex;
                gap: 10px;
                color: lightgray;
            }
        }
    }
}