.treatments{
    display: flex;
    width: 100%;
    background-color: white;

    
    .treatmentsContainer{
        flex: 6;
    }

    .tment{
        padding: 20px;
    }

    .ttments{
        padding: 5px 20px;
    }
}