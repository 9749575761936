.reports{
    display: flex;
    width: 100%;

    
    .reportsContainer{
        flex: 6;
    }

    .rep{
        padding: 20px;
    }

    .rmcs{
        padding: 5px 20px;
    }

    .rmha{
        padding: 5px 20px;
    }

    .rfmha{
        padding: 5px 20px;
    }

    .rpecs{
        padding: 5px 20px;
    }

    .rpamh{
        padding: 5px 20px;
    }

    .rphvs{
        padding: 5px 20px;
    }

    .rpsma{
        padding: 5px 20px;
    }

    .rpscsa{
        padding: 5px 20px;
    }

    .rpfbca{
        padding: 5px 20px;
    }

    .rplftb{
        padding: 5px 20px;
    }

    .rpurinalysis{
        padding: 5px 20px; 
    }
}