.room{
    display: flex;
    width: 100%;
   
    
    .roomContainer{
        flex: 6;

        .roommanage{
            padding: 20px;
        }

        .trackusers{
            padding: 5px 20px;
        }
    }
}