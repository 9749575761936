.sidebarP {
    flex: 1;
    border-right: 0.5px solid rgb(230, 227, 227);
    min-height: 100vh;
    background-color: white;
    align-self: flex-start;
    position: sticky;
    top: 0px;

    .top{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;


          

        .logo{
            font-size: 20px;
            font-weight: bold;
            color: #ca4200;
            display: flex;
            align-items: center;
            gap: 12px;

            img{
                width: 24px;
                height: 24px;
            }
        }
    }

    hr{
        height: 0;
        border: 0.5px solid rgb(230, 227, 227);
    }

    .center{
        padding-left: 10px;

        ul{
            list-style: none;
            margin: 0;
            padding: 0;

            .title{
                font-size: 10px;
                font-weight: bold;
                color: #999;
                margin-top: 15px;
                margin-bottom: 5px;
            }

            li{
                display: flex;
                align-items: center;
                padding: 5px;
                cursor: pointer;


            &:hover {
                    background-color: #ece8ff;
                     }

            }
           
            .icon{
                    font-size: 18px;
                    color: #ca4200;
                }
            span{
                        font-size: 13px;
                        font-weight: 600;
                        color: #888;
                        margin-left: 10px;
                  }

            .spouses{
                padding-left: 20px;
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                border-radius: 10px;
            }
        }
    }

    .bottom{
        display: flex;
        align-items: center;
        margin: 10px;

        .colorOption{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #7451f8;
            cursor: pointer;
            margin: 5px;
        }
    }
}