.schats{

    .userChat{
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: white;
        cursor: pointer;

        &:hover{
            background-color: #ca4200;
        }
        img{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
        }

    }
    
    .userChatInfo{
        span{
            font-size: 18px;
            font-weight: 500;
        }
        p{
            font-size: 14px;
            color: white;
        }
    }
}