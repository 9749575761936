.gynae{
   flex: 4;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    color: gray;
    border-radius: 10px; 
    position: relative;

    .gynaehistory{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;

        .gynaedata{
            display: flex;
            align-self: flex-start;
            padding: 10px;
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.596);
            border-radius: 10px;
            height: 380px;
            flex: 3;

            .gynaedata1{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                margin-bottom: 5px;

                            .pim{
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                gap: 5px;
                
                                input{
                                    margin-bottom: 15px;
                                }
                            }
                            .pim2{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 5px;
                
                                input{
                                    margin-bottom: 15px;
                                }
                            }
            }
        }

        .gynaeboxes{
            display: flex;
            padding: 20px;
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            border-radius: 10px;
            height: 320px;
            flex: 3;

            .gynaedata2{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                margin-bottom: 5px;

                .pim3{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 5px;

                    input{
                        margin-bottom: 15px;
                    }
                }

                .pim4{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 5px;

                    input{
                        margin-bottom: 15px;
                    }
                }
            }
        }

    }

    .btn-pri{
        border: none;
        color: whitesmoke;
        border-radius: 5px;
    }
}