.single{
    display: flex;
    width: 100%;
    background-color: white;

    
    .singleContainer{
        flex: 6;
    }

    .spu{
        padding: 5px 20px;
    }
}