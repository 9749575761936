.pharmacy{
    display: flex;
    width: 100%;
    background-color: white;

    
    .pharmacyContainer{
        flex: 6;
    }

    .pharmacyD{
        padding: 20px;
    }

    .tdrugs{
        padding: 5px 20px;
    }
}