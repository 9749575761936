.reviewr{
    display: flex;
    width: 100%;
    background-color: white;

    .reviewContainer{
        flex: 6;
    }

    .reviewsresult{
        padding: 20px;
    }

    .treview{
        padding: 5px 20px;
    }
}